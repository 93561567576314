

const AdminLayout = ({children}) => {

    return (

       <>            
            {children}
       </>
    )
}

export default AdminLayout