import { Container } from '@mui/material'
import SusunanOrganisasi from "components/section/susunan-organisasi/index";
const StrukturOrganisasi = () => {

    return (
        <>
            <Container sx={{pb:7}}>
                <SusunanOrganisasi />
            </Container>
        </>
    )
}


export default StrukturOrganisasi;